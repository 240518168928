import './Sidebar.scss';
import {useIntl} from "react-intl";
import React from "react";
import LoadingCompSmall from "../../elements/LoadingComp/LoadingCompSmall";
import SubMenu from "./SubMenu";
import ModalComp from "../../ModalComp/ModalComp";
import Button from "../../elements/Button/Button";
import {getNotificatioLink, getNotificatioText, markNotificationAsRead} from "../../../utils/functions";
import {Tooltip} from "react-tooltip";

export default function Sidebar({
                                    sidebarMini,
                                    menuIsOpen,
                                    setMenuIsOpen,
                                    setSidebarMini,
                                    notification,
                                    setNotification,
                                    notifications,
                                    items,
                                    loading,
                                    user,
                                    getData,
                                    context,
                                    setLoadingNotification,
                                    loadingNotification
                                }) {
    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    const delay = ms => new Promise(res => setTimeout(res, ms));

    if ((window.innerWidth <= 576 && menuIsOpen) || window.innerWidth > 576) {
        return (
            <>
                <div className={(sidebarMini ? 'sidebar mini' : 'sidebar')}>
                    <div className={"menu"}>
                        {!sidebarMini &&
                            <div className={"logo"}>
                                <img src={"/images/logo.svg"} alt={""}/>
                            </div>
                        }

                        {items.map((item,i) => (
                            <SubMenu
                                key={item.title + i}
                                item={item}
                                sidebarMini={sidebarMini}
                                setMenuIsOpen={setMenuIsOpen}
                                context={context}
                            />
                        ))}


                    </div>

                    <div className={"user-cont"} onClick={() => {
                        setMenuIsOpen(false)
                        if (window.innerWidth > 576)
                            setSidebarMini(true)

                        if (user.role.id && parseInt(user.role.id) === 4)
                            context.setNextLink('/')
                        else
                            context.setNextLink('/')
                        context.setLeavePage(true)
                    }}>

                        {
                            loading ?
                                <LoadingCompSmall/>
                                :
                                <>
                                    <div className={"icon"} data-tooltip-id={"settings"}>
                                        <i className="fa-solid fa-cog"></i>
                                    </div>
                                    {!sidebarMini ?
                                        <div className={"content"}>
                                            <div className={"name"}>{user.first_name} {user.last_name}</div>
                                        </div>
                                        :

                                        <Tooltip id={"settings"}>{user.first_name} {user.last_name}
                                        </Tooltip>
                                    }
                                </>
                        }

                    </div>

                    <ModalComp
                        title={intl.formatMessage({id: 'general.new_notifications'})}
                        isOpen={notification !== null}
                        className={"sign-contract"}
                        onRequestClose={() => setNotification(null)}
                    >
                        <div className={"content"}>
                            {
                                notification &&
                                <div onClick={() => {
                                    markNotificationAsRead(notification.id, intl, jwt)
                                    if (getNotificatioLink(notification.type, notification.data) !== '/') {

                                        context.setLeavePage(true)
                                        context.setNextLink(getNotificatioLink(notification.type, notification.data))
                                    }
                                }
                                }
                                     style={{cursor: getNotificatioLink(notification.type, notification.data) !== '/' ? 'pointer' : ''}}>
                                    {(() => {

                                        return getNotificatioText(notification,intl)
                                    })()}
                                </div>
                            }


                            {/* {
                        return content}*/}
                        </div>
                        <div className={"footer"}>
                            <div className={"row"}>
                                <div className={"col-12 d-flex"}>
                                    <Button
                                        className={"btn-primary mt-4 ms-auto"}
                                        value={intl.formatMessage({id: notifications.length > 1 ? 'general.next' : 'general.close'})}
                                        type={'button'}
                                        disabled={loadingNotification}
                                        loading={loadingNotification}
                                        onClick={async () => {
                                            setLoadingNotification(true)
                                            markNotificationAsRead(notification.id, intl, jwt)

                                            await delay(1000);
                                            getData(0)
                                            setLoadingNotification(false)
                                        }}
                                    />
                                </div>
                            </div>

                        </div>
                    </ModalComp>

                </div>
                {sidebarMini && items.map((item,j) => (
                    <>
                        <Tooltip key={item.title + j} id={item.title + '_'+item.path}>{item.title}

                        </Tooltip>
                        {item.items &&
                            item.items.map((it, index) => (
                                <Tooltip id={it.title + '_'+it.path} key={ it.title + index}>{it.title}
                                </Tooltip>
                            ))}
                    </>
                ))}
            </>
        );
    }

}

