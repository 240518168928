import { toast } from 'react-toastify';

export default function errorParser(data, intl) {

    switch (data.response.status) {
        case 401:
            if(window.alityAppView) {
                window.ReactNativeWebView.postMessage(JSON.stringify({
                    action: 'clearJwt',
                    data: ''
                }))
            }
            localStorage.clear();
            window.location.replace("/");
            break

        case 400:
            if(data.response && data.response.data && data.response.data.error && data.response.data.error.message) {
                switch (data.response.data.error.message) {
                    case 'error.email.taken':
                        toast.error(intl.formatMessage({id: data.response.data.error.message}))
                        break;
                    default:
                        toast.error(intl.formatMessage({id: 'errors.unknown'}))
                }
            } else {
                toast.error(intl.formatMessage({id: 'errors.unknown'}))
            }

            break

        case 404:
            toast.error(intl.formatMessage({id: 'errors.unknown'}))
            break

        case 403:
            toast.error(intl.formatMessage({id: 'errors.forbidden'}))
            break
        case 429:
            toast.error(intl.formatMessage({id: 'errors.too_many_requests'}))
            break

        default:
            toast.error(intl.formatMessage({id: 'errors.unknown'}))
    }

}
