import Button from "../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../components/ErrorParser/ErrorParser";
import {useNavigate} from "react-router-dom";
import Paginate from "../../../components/elements/Paginate/Paginate";
import LoadingComp from "../../../components/elements/LoadingComp/LoadingComp";
import {toast} from "react-toastify";
import './Clients.scss';

export default function Clients() {

    const intl = useIntl();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')

    const [clients, setClients] = useState([])
    const [search, setSearch] = useState('')

    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(1)
    const [totalMenus, setTotalMenus] = useState(0)

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getData()
        }, 600)

        return () => clearTimeout(delayDebounceFn)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, page])

    const getData = async () => {
        axios.post(process.env.REACT_APP_API + '/clients/list/' + page,
            {search}, {
                headers: {
                    'Authorization': 'Bearer ' + jwt
                }
            })
            .then(async (response) => {
                setClients(response.data.clients ?? [])
                setTotalMenus(response.data.total ?? 0)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const deleteClient = async (id) => {
        axios.delete(process.env.REACT_APP_API + '/clients/' + id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.status === 1) {
                    getData()
                } else {
                    toast.error(intl.formatMessage({id: 'clients.error_delete_client'}))
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="container">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'clients.title'})}</h1>
                </div>

                <div className={"col-md-12 pb-4"}>
        <div className="card">

            <div className={"header"}>

                <div className={"row"}>
                    <div className={"col-md-4 col-sm-12"}>
                        <div className={"search-input-container"}>
                            <i className="fa-solid fa-magnifying-glass"></i>
                            <input type={"text"} className={"form-input"}
                                   placeholder={intl.formatMessage({id: 'general.search'})}
                                   value={search} onChange={(e) => setSearch(e.target.value)}/>
                        </div>
                    </div>
                    <div className={"col-md-8 col-sm-12 float-right"}>
                        <Button
                            className={"btn-primary d-flex align-items-center ms-auto"}
                            icon={"fa-solid fa-plus"}
                            value={intl.formatMessage({id: 'general.buttons.add'})}
                            onClick={() => navigate('/clients/add')}
                        />
                    </div>
                </div>
            </div>


            {
                clients.length === 0 ?
                    search === '' ?
                        <div className={"content"}>

                            <div
                                className={"alert alert-warning"}>{intl.formatMessage({id: 'clients.alert_no_clients'})}</div>
                        </div>
                        :
                        <div className={"content"}>

                            <div
                                className={"alert alert-warning"}>{intl.formatMessage({id: 'clients.alert_no_clients_found'})}</div>
                        </div>
                    :
                    <div className={"content p-0"}>
                        <div className={"table mb-1"}>

                            <div className={"table-head"}>
                                <div className={"row"}>
                                    <div
                                        className={"col-1 table-td"}>{intl.formatMessage({id: 'general.nr'})}</div>

                                    <div
                                        className={"col-3 table-td"}>{intl.formatMessage({id: 'general.name'})}</div>
                                    <div
                                        className={"col-3 table-td"}>{intl.formatMessage({id: 'general.email'})}</div>
                                    <div
                                        className={"col-3 table-td"}>{intl.formatMessage({id: 'general.phone'})}</div>
                                    <div className={"col-2 table-td"}></div>
                                </div>
                            </div>


                            <div className={"table-body"}>
                                {
                                    clients.map((client, i) => (
                                        <div className={"table-row"} key={i}>
                                            <div className={"row"}>
                                                <div
                                                    className={"col-1 table-td"}>
                                                    {i + 1 + (page - 1) * 10}
                                                </div>
                                                <div
                                                    className={"col-3 table-td"}>
                                                    {client.first_name} {client.last_name}
                                                </div>
                                                <div
                                                    className={"col-3 table-td"}>
                                                    {client.email}
                                                </div>
                                                <div
                                                    className={"col-3 table-td"}>
                                                    {client.phone}
                                                </div>
                                                <div className={"col-2 table-td"}>
                                                    <Button
                                                        className={"btn-light btn-edit ms-auto"}
                                                        icon={"fa-solid fa-pen"}
                                                        tooltip={intl.formatMessage({id: 'general.edit'})}
                                                        onClick={() => navigate('/clients/' + client.id + '/edit')}
                                                    />
                                                    <Button
                                                        className={"btn-light btn-delete"}
                                                        icon={"fa-solid fa-trash"}
                                                        tooltip={intl.formatMessage({id: 'general.delete'})}
                                                        onClick={() => deleteClient(client.id)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
            }

            <div className={"footer d-flex justify-content-center"}>
                <Paginate
                    page={page}
                    setPage={setPage}
                    totalItems={totalMenus}
                />

            </div>
        </div>
                </div>
            </div>
        </div>
    );
}

