import Button from "../../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../../../components/ErrorParser/ErrorParser";
import {useNavigate, useParams} from "react-router-dom";
import LoadingComp from "../../../../../components/elements/LoadingComp/LoadingComp";

export default function Places() {

    const intl = useIntl();
    const navigate = useNavigate()
    let params = useParams();
    const jwt = localStorage.getItem('jwt')

    const [places, setPlaces] = useState([])

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/place-of-consumptions/findPlacesForClient/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setPlaces(response.data ?? [])
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="card">

            <div className={"header"}>

                <div className={"row"}>
                    <div className={"col-md-4 col-sm-12"}>
                    </div>
                    <div className={"col-md-8 col-sm-12 float-right"}>
                        <Button
                            className={"btn-primary d-flex align-items-center ms-auto"}
                            icon={"fa-solid fa-plus"}
                            value={intl.formatMessage({id: 'general.buttons.add'})}
                            onClick={() => navigate('/clients/' + params.id + '/places/add')}
                        />
                    </div>
                </div>
            </div>


            {
                places.length === 0 ?
                        <div className={"content"}>

                            <div
                                className={"alert alert-warning"}>{intl.formatMessage({id: 'clients.alert_no_places'})}</div>
                        </div>
                    :
                    <div className={"content p-0"}>
                        <div className={"table mb-1"}>

                            <div className={"table-head"}>
                                <div className={"row"}>
                                    <div
                                        className={"col-1 table-td"}>{intl.formatMessage({id: 'general.nr'})}</div>

                                    <div
                                        className={"col-4 table-td"}>{intl.formatMessage({id: 'general.address'})}</div>
                                    <div
                                        className={"col-3 table-td"}>{intl.formatMessage({id: 'general.city'})}</div>
                                    <div
                                        className={"col-3 table-td"}>{intl.formatMessage({id: 'general.county'})}</div>
                                    <div className={"col-1 table-td"}></div>
                                </div>
                            </div>


                            <div className={"table-body"}>
                                {
                                    places.map((item, i) => (
                                        <div className={"table-row"} key={i}>
                                            <div className={"row"}>
                                                <div
                                                    className={"col-1 table-td"}>
                                                    {i + 1}
                                                </div>

                                                <div
                                                    className={"col-4 table-td"}>
                                                    {item.address}
                                                </div>

                                                <div
                                                    className={"col-3 table-td"}>
                                                    {item.city}
                                                </div>
                                                <div
                                                    className={"col-3 table-td"}>
                                                    {item.county}
                                                </div>
                                                <div className={"col-1 table-td"}>
                                                    <Button
                                                        className={"btn-light btn-edit ms-auto"}
                                                        icon={"fa-solid fa-pen"}
                                                        tooltip={intl.formatMessage({id: 'general.edit'})}
                                                        onClick={() => navigate('/clients/'+params.id+'/places/' + item.id)}
                                                    />

                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
            }
            <div className={"footer d-flex justify-content-center"}>

            </div>
        </div>
    );
}

