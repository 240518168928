import SecondaryNavigation from "../../../../components/elements/SecondaryNavigation/SecondaryNavigation";
import {Outlet, useNavigate, useOutletContext, useParams} from "react-router-dom";
import {useIntl} from "react-intl";
import {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../../components/ErrorParser/ErrorParser";

export default function CompanyInfo() {

    const intl = useIntl()
    const navigate = useNavigate()
    let params = useParams();
    const jwt = localStorage.getItem('jwt')

    const [eventName, setEventName] = useState()
    const {context} = useOutletContext()

    useEffect(() => {
        if (window.location.pathname === '/company/' + params.id){
            navigate('/company/'+params.id+'/users')
        }

        getData()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/companies/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if(response.data){
                    setEventName(response.data.title)
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    return (
        <div className="container">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{eventName}</h1>
                </div>

                <div className={"col-lg-12 col-xl-2 pb-4"}>

                    <SecondaryNavigation
                        context={context}
                        items={[
                            {
                                'icon': "fa-solid fa-users",
                                'title': intl.formatMessage({id: 'settings.menu.users'}),
                                'url': '/company/'+params.id+'/users',
                            },
                            {
                                'icon': "fa-solid fa-calendar-check",
                                'title': intl.formatMessage({id: 'settings.menu.subscription'}),
                                'url': '/company/'+params.id+'/subscription'
                            },
                        ]}
                    />
                </div>
                <div className={"col-lg-12 col-xl-10"}>
                    <Outlet  context={{context}}/>
                </div>
            </div>




        </div>
    );
}

