import SecondaryNavigation from "../../../../components/elements/SecondaryNavigation/SecondaryNavigation";
import {Outlet, useNavigate, useOutletContext} from "react-router-dom";
import {useIntl} from "react-intl";
import {useEffect} from "react";

export default function CompanyMenu() {

    const intl = useIntl()
    const navigate = useNavigate()
    const {context} = useOutletContext()

    useEffect(() => {
        if (window.location.pathname === '/company'){
            navigate('/company/edit')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div className="container">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'general.sidebar.company'})}</h1>
                </div>

                <div className={"col-md-12 col-lg-3 col-xl-2 pb-4"}>

                    <SecondaryNavigation
                        context={context}
                        items={[
                            {
                                'icon': "fa-solid fa-pen",
                                'title': intl.formatMessage({id: 'settings.menu.edit_company'}),
                                'url': '/company/edit',
                            },
                            {
                                'icon': "fa-solid fa-percent",
                                'title': intl.formatMessage({id: 'general.vat'}),
                                'url': '/company/vats'
                            },
                            {
                                'icon': "fa-solid fa-file",
                                'title': intl.formatMessage({id: 'settings.menu.documents_series'}),
                                'url': '/company/documents-series'
                            },
                        ]}
                    />
                </div>
                <div className={"col-md-12 col-lg-9 col-xl-10"}>
                    <Outlet  context={{context}}/>
                </div>
            </div>




        </div>
    );
}

