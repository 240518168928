import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../../components/ErrorParser/ErrorParser";
import {Outlet, useOutletContext, useParams} from "react-router-dom";
import LoadingComp from "../../../../components/elements/LoadingComp/LoadingComp";
import '../Requests.scss'
import SecondaryNavigation
    from "../../../../components/elements/SecondaryNavigation/SecondaryNavigation";
import {requestStatuses} from "../../../../utils/constants";

export default function EditRequestMenu() {

    const intl = useIntl();
    let params = useParams();
    const jwt = localStorage.getItem('jwt')
    const {context} = useOutletContext()


    const [statusIndex, setStatusIndex] = useState(0)
    const [request, setRequest] = useState(null)

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.contentUpdated])
    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/project-requests/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setStatusIndex(requestStatuses.indexOf(response.data.status))
                setRequest(response.data)
                context.setContentUpdated(false)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="container">

            <div className={"row"}>
                <div className={"col-md-8 col-sm-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'requests.edit_request'})}
                        : {intl.formatMessage({id: 'requests.type.'+ request.type})}
                    </h1>
                </div>
                <div className={"col-md-4 col-sm-12"}>
                    <p className={"status-container"}>
                        {intl.formatMessage({id: 'requests.status.'+ request.status})}
                    </p>
                </div>

                <div className={"col-md-12 col-lg-3 col-xl-2 pb-4"}>
                    <SecondaryNavigation
                        context={context}
                        items={[
                            {
                                'icon': "fa-solid fa-pen",
                                'title': intl.formatMessage({id: 'general.edit'}),
                                'url': '/requests/'+params.id+'/edit',
                            },
                            {
                                'icon': "fa-solid fa-upload",
                                'title': intl.formatMessage({id: 'requests.upload_documents'}),
                                'url': '/requests/'+params.id+'/documents',
                            },
                            {
                                'icon': "fa-solid fa-upload",
                                'title': intl.formatMessage({id: 'requests.upload_documents'}),
                                'url': '/requests/'+params.id+'/documents',
                                'disabled': statusIndex < 2
                            },
                        ]}
                    />
                </div>
                <div className={"col-md-12 col-lg-9 col-xl-10"}>
                    <Outlet  context={{context}}/>
                </div>
            </div>




        </div>
    );
}

