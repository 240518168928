import './Login.scss';
import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import Button from "../../../components/elements/Button/Button";
import axios from "axios";
import {toast} from "react-toastify";
import LoadingComp from "../../../components/elements/LoadingComp/LoadingComp";
import {useNavigate} from "react-router-dom";

export default function Login() {

    const intl = useIntl();
    const query = new URLSearchParams(window.location.search);
    const navigate = useNavigate()

    const [email, setEmail] = useState(query.get('xd') ? atob(query.get('xd')) : '')
    const [password, setPassword] = useState(query.get('cd') ? atob(query.get('cd')) : '')

    const [loading, setLoading] = useState(false)
    const [initialLoading, setInitialLoading] = useState(true)

    useEffect(() => {
        //http://localhost:3000/hl?xd=aWxpZS5tYWRhbGluYUBtYWlsLnJ1&cd=VDhUa3M2bmY=
        if (query.get('xd') && query.get('cd')) {
            signIn()
        } else {
            setInitialLoading(false)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const signIn = async (e) => {
        e.preventDefault()
        setLoading(true)

        let obj = {
            identifier: email,
            password: password
        }

        await axios.post(process.env.REACT_APP_API + '/auth/login', obj)
            .then(async (response) => {
                localStorage.setItem('role', response.data.user.role.id + '')
                localStorage.setItem('access', JSON.stringify(response.data.user.access))
                localStorage.setItem('jwt', response.data.jwt)
                window.location.replace("/");
            })
            .catch(err => {
                if (err.response.data && err.response.data.data && err.response.data.data.error === 'error.confirmed') {
                    navigate('/verify-email/' + err.response.data.data.id)
                } else
                    toast.error(intl.formatMessage({id: 'errors.invalid_credentials'}))
            })

        setLoading(false)
    }

    if (initialLoading) {
        return <LoadingComp/>
    }

    return (
        <div className="login">
            <div className={"logo"}>
                <img src={"/images/logo.svg"} alt={""}/>
            </div>
            <form
                onSubmit={signIn}>
                <div className={"container"}>
                    <div className={"form-label"}>{intl.formatMessage({id: 'general.email'})}</div>
                    <input type={"email"}
                           className={"form-input"}
                           placeholder={intl.formatMessage({id: 'general.email'})}
                           value={email}
                           required
                           onChange={(e) => setEmail(e.target.value)}/>

                    <div
                        className={"form-label mt-3"}>{intl.formatMessage({id: 'login.password'})}</div>
                    <input type={"password"}
                           className={"form-input"}
                           required
                           placeholder={intl.formatMessage({id: 'login.password'})}
                           value={password}
                           onChange={(e) => setPassword(e.target.value)}/>

                    <Button
                        className={"btn-primary mt-4"}
                        value={intl.formatMessage({id: 'login.signin'})}
                        type={"submit"}
                        loading={loading}
                    />


                    <Button
                        className={"btn-primary mt-4"}
                        value={intl.formatMessage({id: 'login.signup'})}
                        onClick={() => navigate('/register')}
                    />

                    <a href={"/forgot-password"} className={"mt-3"} style={{textAlign: 'center'}}>
                        {intl.formatMessage({id: 'login.forgot_password'})}
                    </a>

                </div>
            </form>
        </div>
    );
}

