import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import Button from "../../../components/elements/Button/Button";
import axios from "axios";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";

export default function ForgotPassword() {

    const intl = useIntl();
    const navigate = useNavigate()

    const [password, setPassword] = useState('')
    const [rpt, setRpt] = useState('')
    const [repeatPassword, setRepeatPassword] = useState('')
    const query = new URLSearchParams(window.location.search);

    const [loading, setLoading] = useState(false)
    useEffect(() => {
        if(query.get('rpt')){
            setRpt(atob(query.get('rpt')))
        }else {
            window.location.href = '/'
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const signIn = async () => {
        if (!password || !repeatPassword || password !== repeatPassword) {
            toast.error(intl.formatMessage({id: 'errors.passwords_not_match'}))
            return
        }
        setLoading(true)
        let obj = {
            password: password,
            resetPasswordToken: rpt
        }

        await axios.post(process.env.REACT_APP_API + ('/reset-password'), obj)
            .then(async (response) => {
                if (response.status) {
                    toast.success(intl.formatMessage({id: 'login.reset_password_success'}))
                    navigate('/')
                }else{
                    toast.error(intl.formatMessage({id: 'errors.unknown'}))
                }
            })
            .catch(err => {
                toast.error(intl.formatMessage({id: 'errors.unknown'}))
            })

        setLoading(false)
    }

    return (
        <div className="login">

            <div className={"logo"}>
                <img src={"/images/logo.svg"} alt={""}/>
            </div>
            <div className={"container"}>
                <h1>{intl.formatMessage({id: 'login.reset_password'})}</h1>
                <div className={"form-control"}>
                    <div
                        className={"form-label"}>{intl.formatMessage({id: 'settings.account.new_password'})}
                        <span className={"form-required"}>*</span></div>
                    <input type={"password"} className={"form-input"} required
                           value={password} onChange={(e) => setPassword(e.target.value)}/>
                </div>

                <div className={"form-control"}>
                    <div
                        className={"form-label"}>{intl.formatMessage({id: 'settings.account.confirm_new_password'})}
                        <span className={"form-required"}>*</span></div>
                    <input type={"password"} className={"form-input"}
                           value={repeatPassword} required
                           onChange={(e) => setRepeatPassword(e.target.value)}/>
                </div>
                <Button
                    className={"btn-primary mt-4"}
                    value={intl.formatMessage({id: 'login.reset'})}
                    onClick={signIn}
                    loading={loading}
                />
                <a href={"/"} className={"mt-3"} style={{textAlign: 'center'}}>
                    {intl.formatMessage({id: 'login.signin'})}
                </a>

            </div>
        </div>
    );
}

