import {useIntl} from "react-intl";
import React from "react";
import AddClientForm from "./AddClientForm";

export default function AddClient() {

    const intl = useIntl();

    return (
        <div className="container">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'clients.title'})}</h1>
                </div>

                <div className={"col-md-12 pb-4"}>
        <div className="card">
            <div className={"header"}><p>{intl.formatMessage({id: 'clients.add_client'})}</p>

            </div>

             <AddClientForm
                 id={0}
             />

        </div>
                </div>
            </div>
        </div>
    );
}

