import Button from "../elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useState} from "react";
import {toast} from "react-toastify";
import axios from "axios";
import errorParser from "../ErrorParser/ErrorParser";
import ModalComp from "../ModalComp/ModalComp";

export default function ModalDeleteAccount({modalIsOpen,setModalIsOpen}) {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')

    const [saveLoading, setSaveLoading] = useState(false)

    const deleteAccount = async () => {
        setSaveLoading(true)
        await axios.get(process.env.REACT_APP_API + '/users/deleteAccount', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                localStorage.clear();
                window.location.replace("/");
            })
            .catch(err => {
                errorParser(err, intl)
            })

        setSaveLoading(false)
    }



    return (
        <ModalComp
            title={intl.formatMessage({id: 'settings.delete_account'})}
            isOpen={modalIsOpen}
            className={"sign-contract"}
            close={false}
        >
            <div className={"content"}>

                <div>
                    {intl.formatMessage({id: 'settings.are_sure_delete_account'})}
                </div>
            </div>
            <div className={"footer"}>
                <div className={"row"}>
                    <div className={"col-md-6 col-12"}>
                        <Button
                            className={"btn-light btn-error mt-4"}
                            value={intl.formatMessage({id: 'general.yes'})}
                            onClick={() => deleteAccount()}
                            disabled={saveLoading}
                            loading={saveLoading}
                            type={'button'}
                        />
                    </div>
                    <div className={"col-md-6 col-12 d-flex"}>
                        <Button
                            className={"btn-primary mt-4 ms-auto"}
                            value={intl.formatMessage({id: 'general.no'})}
                            type={'button'}
                            onClick={() => {
                                setModalIsOpen(false)
                            }}
                        />
                    </div>
                </div>

            </div>

        </ModalComp>
    );
}

