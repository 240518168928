import Button from "../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../components/ErrorParser/ErrorParser";
import {useNavigate} from "react-router-dom";
import Paginate from "../../../components/elements/Paginate/Paginate";
import LoadingComp from "../../../components/elements/LoadingComp/LoadingComp";
import {toast} from "react-toastify";

export default function Requests() {

    const intl = useIntl();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')

    const [requests, setRequests] = useState([])

    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getData()
        }, 600)

        return () => clearTimeout(delayDebounceFn)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/project-requests/list/' + page, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setRequests(response.data.requests ?? [])
                setTotal(response.data.total ?? 0)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const deleteRequest = async (id) => {
        axios.delete(process.env.REACT_APP_API + '/project-requests/' + id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.status === 1) {
                    getData()
                } else {
                    toast.error(intl.formatMessage({id: 'requests.error_delete_requests'}))
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="container">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'requests.title'})}</h1>
                </div>

                <div className={"col-md-12 pb-4"}>
                    <div className="card">

                        <div className={"header"}>

                            <div className={"row"}>
                                <div className={"col-md-4 col-sm-12"}>
                                </div>
                                <div className={"col-md-8 col-sm-12 float-right"}>
                                    <Button
                                        className={"btn-primary d-flex align-items-center ms-auto"}
                                        icon={"fa-solid fa-plus"}
                                        value={intl.formatMessage({id: 'general.buttons.add'})}
                                        onClick={() => navigate('/requests/add')}
                                    />
                                </div>
                            </div>
                        </div>


                        {
                            requests.length === 0 ?
                                <div className={"content"}>

                                    <div
                                        className={"alert alert-warning"}>{intl.formatMessage({id: 'requests.alert_no_requests'})}</div>
                                </div>
                                :
                                <div className={"content p-0"}>
                                    <div className={"table mb-1"}>

                                        <div className={"table-head"}>
                                            <div className={"row"}>
                                                <div
                                                    className={"col-1 table-td"}>{intl.formatMessage({id: 'general.nr'})}</div>

                                                <div
                                                    className={"col-2 table-td"}>{intl.formatMessage({id: 'general.type'})}</div>
                                                <div
                                                    className={"col-4 table-td"}>{intl.formatMessage({id: 'general.client'})}</div>
                                                <div
                                                    className={"col-3 table-td"}>{intl.formatMessage({id: 'general.status'})}</div>
                                                <div className={"col-2 table-td"}></div>
                                            </div>
                                        </div>


                                        <div className={"table-body"}>
                                            {
                                                requests.map((request, i) => (
                                                    <div className={"table-row"} key={i}>
                                                        <div className={"row"}>
                                                            <div
                                                                className={"col-1 table-td"}>
                                                                {i + 1 + (page - 1) * 10}
                                                            </div>
                                                            <div
                                                                className={"col-2 table-td"}>

                                                                {intl.formatMessage({id: 'requests.type.' + request.type})}
                                                            </div>
                                                            <div
                                                                className={"col-4 table-td"}>
                                                                {request.client.type === 'individual' ? (request.client.first_name + ' ' + request.client.last_name)
                                                                    : request.client.company_title}
                                                            </div>
                                                            <div
                                                                className={"col-3 table-td"}>
                                                                {intl.formatMessage({id: 'requests.status.' + request.status})}
                                                            </div>
                                                            <div className={"col-2 table-td"}>
                                                                <Button
                                                                    className={"btn-light btn-edit ms-auto"}
                                                                    icon={"fa-solid fa-pen"}
                                                                    tooltip={intl.formatMessage({id: 'general.edit'})}
                                                                    onClick={() => navigate('/requests/' + request.id + '/edit')}
                                                                />
                                                                <Button
                                                                    className={"btn-light btn-delete"}
                                                                    icon={"fa-solid fa-trash"}
                                                                    tooltip={intl.formatMessage({id: 'general.delete'})}
                                                                    onClick={() => deleteRequest(request.id)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                        }

                        <div className={"footer d-flex justify-content-center"}>
                            <Paginate
                                page={page}
                                setPage={setPage}
                                totalItems={total}
                            />

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

