import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../../components/ErrorParser/ErrorParser";
import {useOutletContext, useParams} from "react-router-dom";
import LoadingComp from "../../../../components/elements/LoadingComp/LoadingComp";
import '../Requests.scss'
import Button from "../../../../components/elements/Button/Button";
import {toast} from "react-toastify";
import UploadFile from "../../../../components/Upload/UploadFile";

export default function UploadDocumets() {

    const intl = useIntl();
    let params = useParams();
    const jwt = localStorage.getItem('jwt')
    const {context} = useOutletContext()


    const [type, setType] = useState(null)
    const [request, setRequest] = useState(null)

    const [identityCard, setIdentityCard] = useState(null)
    const [companyId, setCompanyId] = useState(null)
    const [consumptionPlaceCodeOrSupplierInvoice, setConsumptionPlaceCodeOrSupplierInvoice] = useState('code')
    const [gasSupplierInvoice, setGasSupplierInvoice] = useState(null)
    const [consumptionPlaceCode, setConsumptionPlaceCode] = useState(null)
    const [isometricDiagram, setIsometricDiagram] = useState(null)
    const [centralPeriodicTechnicalCheck, setCentralPeriodicTechnicalCheck] = useState(null)
    const [gasInstallationFileNumber, setGasInstallationFileNumber] = useState(null)
    const [sealNumber, setSealNumber] = useState(null)
    const [counterSeries, setCounterSeries] = useState(null)
    const [counterIndex, setCounterIndex] = useState(null)
    const [pvDistrigaz, setPvDistrigaz] = useState(null)
    const [propertyPlan, setPropertyPlan] = useState(null)
    const [propertyDeed, setPropertyDeed] = useState(null)
    const [floorsPlan, setFloorsPlan] = useState(null)
    const [constructionAuthorization, setConstructionAuthorization] = useState(null)
    const [plansAnnexToConstructionAuthorization, setPlansAnnexToConstructionAuthorization] = useState(null)
    const [areaPlans, setAreaPlans] = useState(null)
    const [cadastralPlanWithStereoCoordinates, setCadastralPlanWithStereoCoordinates] = useState(null)
    const [orthophotoPlan, setOrthophotoPlan] = useState(null)
    const [landCertificateExtract, setLandCertificateExtract] = useState(null)
    const [notarialDeclarationForLitigation, setNotarialDeclarationForLitigation] = useState(null)
    const [notarialDeclarationFoCompensation, setNotarialDeclarationFoCompensation] = useState(null)
    const [hasAttr, setHasAttr] = useState(null)

    const [loading, setLoading] = useState(true)
    const [saveLoading, setSaveLoading] = useState(false)

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/project-requests/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setRequest(response.data)
                setType(response.data.type)
                setConsumptionPlaceCode(response.data.consumption_place_code)
                setGasInstallationFileNumber(response.data.gas_installation_file_number)
                setSealNumber(response.data.seal_number)
                setCounterSeries(response.data.counter_series)
                setCounterIndex(response.data.counter_index)
                setHasAttr(response.data.has_attr)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }


    const save = async (e) => {
        e.preventDefault();
        setSaveLoading(true)
        let formData = new FormData()
        let data = {has_attr: hasAttr}

        if (identityCard) {
            formData.append('identity_card', identityCard)
        }
        if (companyId) {
            formData.append('company_id', companyId)
        }
        if (consumptionPlaceCodeOrSupplierInvoice === 'code') {
            data.consumption_place_code = consumptionPlaceCode
        }
        if (consumptionPlaceCodeOrSupplierInvoice === 'invoice' && gasSupplierInvoice) {
            formData.append('gas_supplier_invoice', gasSupplierInvoice)
        }

        if (isometricDiagram) {
            formData.append('isometric_diagram', isometricDiagram)
        }
        if (gasInstallationFileNumber) {
            data.gas_installation_file_number = gasInstallationFileNumber
        }
        if (centralPeriodicTechnicalCheck) {
            formData.append('central_periodic_technical_check', centralPeriodicTechnicalCheck)
        }
        if (sealNumber) {
            data.seal_number = sealNumber
        }
        if (counterSeries) {
            data.counter_series = counterSeries
        }
        if (counterIndex) {
            data.counter_index = counterIndex
        }
        if (propertyPlan) {
            formData.append('property_plan', propertyPlan)
        }
        if (propertyDeed) {
            formData.append('property_deed', propertyDeed)
        }
        if (floorsPlan) {
            formData.append('floors_plan', floorsPlan)
        }

        if (constructionAuthorization) {
            formData.append('construction_authorization', constructionAuthorization)
        }

        if (plansAnnexToConstructionAuthorization) {
            formData.append('plans_annex_to_construction_authorization', plansAnnexToConstructionAuthorization)
        }

        if (areaPlans) {
            formData.append('area_plans', areaPlans)
        }

        if (cadastralPlanWithStereoCoordinates) {
            formData.append('cadastral_plan_with_stereo_coordinates', cadastralPlanWithStereoCoordinates)
        }

        if (orthophotoPlan) {
            formData.append('orthophoto_plan', orthophotoPlan)
        }

        if (landCertificateExtract) {
            formData.append('land_certificate_extract', landCertificateExtract)
        }
        if (notarialDeclarationForLitigation) {
            formData.append('notarial_declaration_for_litigation', notarialDeclarationForLitigation)
        }
        if (notarialDeclarationFoCompensation) {
            formData.append('notarial_declaration_for_compensation', notarialDeclarationFoCompensation)
        }

        formData.append('data', JSON.stringify(data))

        await axios.put(process.env.REACT_APP_API + '/project-requests/upload-documents/' + params.id, formData, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (parseInt(response.data.status) === 1) {
                    toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                    context.setContentUpdated(true)
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
        setSaveLoading(false)
    }
    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="card">
            <div className={"header"}>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <p>{intl.formatMessage({id: 'requests.upload_documents'})}</p>
                    </div>
                </div>
            </div>

            <form onSubmit={(e) => save(e)}>
                <div className={"content"}>
                    <div className={"row"}>
                        {
                            request.client && request.client.type === 'individual' ?
                                //buletin - fisier
                                <div className={"col-12"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'requests.identity_card'})}<span
                                        className={"form-required"}>*</span>
                                        {
                                            identityCard === null && request.identity_card &&
                                            <a href={process.env.REACT_APP_API.replace('/api', '') + request.identity_card.url}
                                               target={"_blank"} rel={"noreferrer"}>
                                                <i className={"fa-solid fa-eye ms-2"}/>
                                            </a>
                                        }
                                    </div>
                                    <UploadFile
                                        file={identityCard}
                                        setFile={setIdentityCard}
                                        className={"p-3"}
                                    />
                                </div>
                                :
                                //cui daca e firma - fisier
                                <div className={"col-12"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'requests.company_id'})}<span
                                        className={"form-required"}>*</span>
                                        {
                                            companyId === null && request.company_id &&
                                            <a href={process.env.REACT_APP_API.replace('/api', '') + request.company_id.url}
                                               target={"_blank"} rel={"noreferrer"}>
                                                <i className={"fa-solid fa-eye ms-2"}/>
                                            </a>
                                        }
                                    </div>
                                    <UploadFile
                                        file={companyId}
                                        setFile={setCompanyId}
                                        className={"p-3"}
                                    />
                                </div>
                        }
                        {
                            //factura de la furnizorul de gaz(fisier)/ cod loc consum(text)
                            type !== 'connection' &&
                            <>
                                <div className={"tab-switch"}>
                                    <div
                                        className={"tab " + (consumptionPlaceCodeOrSupplierInvoice === 'code' ? "active" : "")}
                                        onClick={() => {
                                            setConsumptionPlaceCodeOrSupplierInvoice('code')
                                        }}>
                                        {intl.formatMessage({id: 'requests.consumption_place_code'})}
                                    </div>
                                    <div
                                        className={"tab " + (consumptionPlaceCodeOrSupplierInvoice === 'invoice' ? "active" : "")}
                                        onClick={() => {
                                            setConsumptionPlaceCodeOrSupplierInvoice('invoice')
                                        }}>
                                        {intl.formatMessage({id: 'requests.gas_supplier_invoice'})}
                                    </div>
                                </div>
                                {consumptionPlaceCodeOrSupplierInvoice === 'code' &&
                                    <div className={"col-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>{intl.formatMessage({id: 'requests.consumption_place_code'})}<span
                                                className={"form-required"}>*</span></div>
                                            <input type={"text"} className={"form-input"}

                                                   value={consumptionPlaceCode} required
                                                   onChange={(e) => {
                                                       setConsumptionPlaceCode(e.target.value)
                                                   }}
                                            />
                                        </div>
                                    </div>}
                                {consumptionPlaceCodeOrSupplierInvoice === 'invoice' &&
                                    <div className={"col-12"}>
                                        <div
                                            className={"form-label"}>{intl.formatMessage({id: 'requests.gas_supplier_invoice'})}<span
                                            className={"form-required"}>*</span>
                                            {
                                                gasSupplierInvoice === null && request.gas_supplier_invoice &&
                                                <a href={process.env.REACT_APP_API.replace('/api', '') + request.gas_supplier_invoice.url}
                                                   target={"_blank"} rel={"noreferrer"}>
                                                    <i className={"fa-solid fa-eye ms-2"}/>
                                                </a>
                                            }
                                        </div>
                                        <UploadFile
                                            file={gasSupplierInvoice}
                                            setFile={setGasSupplierInvoice}
                                            className={"p-3"}
                                        />
                                    </div>}
                            </>
                        }
                        {
                            (type === 'check' || type === 'revision') &&
                            <>
                                {/* schema izometrica-fisier*/}
                                <div className={"col-12"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'requests.isometric_diagram'})}<span
                                        className={"form-required"}>*</span>
                                        {
                                            isometricDiagram === null && request.isometric_diagram &&
                                            <a href={process.env.REACT_APP_API.replace('/api', '') + request.isometric_diagram.url}
                                               target={"_blank"} rel={"noreferrer"}>
                                                <i className={"fa-solid fa-eye ms-2"}/>
                                            </a>
                                        }
                                    </div>
                                    <UploadFile
                                        file={isometricDiagram}
                                        setFile={setIsometricDiagram}
                                        className={"p-3"}
                                    />
                                </div>

                                {/*-nr de dosar instalatie gaze*/}
                                <div className={"col-12"}>
                                    <div className={"form-control"}>
                                        <div
                                            className={"form-label"}>{intl.formatMessage({id: 'requests.gas_installation_file_number'})}<span
                                            className={"form-required"}>*</span></div>
                                        <input type={"text"} className={"form-input"}

                                               value={gasInstallationFileNumber} required
                                               onChange={(e) => {
                                                   setGasInstallationFileNumber(e.target.value)
                                               }}
                                        />
                                    </div>
                                </div>
                                {/*vtp centrala*/}
                                <div className={"col-12"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'requests.central_periodic_technical_check'})}<span
                                        className={"form-required"}>*</span>
                                        {
                                            centralPeriodicTechnicalCheck === null && request.central_periodic_technical_check &&
                                            <a href={process.env.REACT_APP_API.replace('/api', '') + request.central_periodic_technical_check.url}
                                               target={"_blank"} rel={"noreferrer"}>
                                                <i className={"fa-solid fa-eye ms-2"}/>
                                            </a>
                                        }
                                    </div>
                                    <UploadFile
                                        file={centralPeriodicTechnicalCheck}
                                        setFile={setCentralPeriodicTechnicalCheck}
                                        className={"p-3"}
                                    />
                                </div>
                            </>

                        }
                        {
                            type === 'revision' && <>
                                {/*nr sigiliu*/}
                                <div className={"col-12"}>
                                    <div className={"form-control"}>
                                        <div
                                            className={"form-label"}>{intl.formatMessage({id: 'requests.seal_number'})}<span
                                            className={"form-required"}>*</span></div>
                                        <input type={"text"} className={"form-input"}

                                               value={sealNumber} required
                                               onChange={(e) => {
                                                   setSealNumber(e.target.value)
                                               }}
                                        />
                                    </div>
                                </div>
                                {/*serie contor*/}
                                <div className={"col-12"}>
                                    <div className={"form-control"}>
                                        <div
                                            className={"form-label"}>{intl.formatMessage({id: 'requests.counter_series'})}<span
                                            className={"form-required"}>*</span></div>
                                        <input type={"text"} className={"form-input"}

                                               value={counterSeries} required
                                               onChange={(e) => {
                                                   setCounterSeries(e.target.value)
                                               }}
                                        />
                                    </div>
                                </div>
                                {/*index contor*/}
                                <div className={"col-12"}>
                                    <div className={"form-control"}>
                                        <div
                                            className={"form-label"}>{intl.formatMessage({id: 'requests.counter_index'})}<span
                                            className={"form-required"}>*</span></div>
                                        <input type={"text"} className={"form-input"}

                                               value={counterIndex} required
                                               onChange={(e) => {
                                                   setCounterIndex(e.target.value)
                                               }}
                                        />
                                    </div>
                                </div>
                                {/*proces verbal distrigaz*/}
                                <div className={"col-12"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'requests.pv_distrigaz'})}<span
                                        className={"form-required"}>*</span>
                                        {
                                            pvDistrigaz === null && request.pv_distrigaz &&
                                            <a href={process.env.REACT_APP_API.replace('/api', '') + request.pv_distrigaz.url}
                                               target={"_blank"} rel={"noreferrer"}>
                                                <i className={"fa-solid fa-eye ms-2"}/>
                                            </a>
                                        }
                                    </div>
                                    <UploadFile
                                        file={pvDistrigaz}
                                        setFile={setPvDistrigaz}
                                        className={"p-3"}
                                    />
                                </div>
                            </>
                        }

                        {
                            (type === 'installation' || type === 'connection') &&
                            <>
                                <div className={"col-12"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'requests.property_plan'})}<span
                                        className={"form-required"}>*</span>
                                        {
                                            propertyPlan === null && request.property_plan &&
                                            <a href={process.env.REACT_APP_API.replace('/api', '') + request.property_plan.url}
                                               target={"_blank"} rel={"noreferrer"}>
                                                <i className={"fa-solid fa-eye ms-2"}/>
                                            </a>
                                        }
                                    </div>
                                    <UploadFile
                                        file={propertyPlan}
                                        setFile={setPropertyPlan}
                                        className={"p-3"}
                                    />
                                </div>
                                <div className={"col-12"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'requests.property_deed'})}<span
                                        className={"form-required"}>*</span>
                                        {
                                            propertyDeed === null && request.property_deed &&
                                            <a href={process.env.REACT_APP_API.replace('/api', '') + request.property_deed.url}
                                               target={"_blank"} rel={"noreferrer"}>
                                                <i className={"fa-solid fa-eye ms-2"}/>
                                            </a>
                                        }
                                    </div>
                                    <UploadFile
                                        file={propertyDeed}
                                        setFile={setPropertyDeed}
                                        className={"p-3"}
                                    />
                                </div>
                            </>
                        }
                        {
                            type === 'installation' &&
                            <div className={"col-12"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'requests.floors_plan'})}<span
                                    className={"form-required"}>*</span>
                                    {
                                        floorsPlan === null && request.floors_plan &&
                                        <a href={process.env.REACT_APP_API.replace('/api', '') + request.floors_plan.url}
                                           target={"_blank"} rel={"noreferrer"}>
                                            <i className={"fa-solid fa-eye ms-2"}/>
                                        </a>
                                    }
                                </div>
                                <UploadFile
                                    multiple={true}
                                    file={floorsPlan}
                                    setFile={setFloorsPlan}
                                    className={"p-3"}
                                />
                            </div>
                        }
                        {
                            type === 'connection' &&
                            <>
                                <div className={"col-12"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'requests.construction_authorization'})}<span
                                        className={"form-required"}>*</span>
                                        {
                                            constructionAuthorization === null && request.construction_authorization &&
                                            <a href={process.env.REACT_APP_API.replace('/api', '') + request.construction_authorization.url}
                                               target={"_blank"} rel={"noreferrer"}>
                                                <i className={"fa-solid fa-eye ms-2"}/>
                                            </a>
                                        }
                                    </div>
                                    <UploadFile
                                        file={constructionAuthorization}
                                        setFile={setConstructionAuthorization}
                                        className={"p-3"}
                                    />
                                </div>
                                <div className={"col-12"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'requests.plans_annex_to_construction_authorization'})}<span
                                        className={"form-required"}>*</span>
                                        {
                                            plansAnnexToConstructionAuthorization === null && request.plans_annex_to_construction_authorization &&
                                            <a href={process.env.REACT_APP_API.replace('/api', '') + request.plans_annex_to_construction_authorization.url}
                                               target={"_blank"} rel={"noreferrer"}>
                                                <i className={"fa-solid fa-eye ms-2"}/>
                                            </a>
                                        }
                                    </div>
                                    <UploadFile
                                        file={plansAnnexToConstructionAuthorization}
                                        setFile={setPlansAnnexToConstructionAuthorization}
                                        className={"p-3"}
                                    />
                                </div>
                                <div className={"col-12"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'requests.area_plans'})}<span
                                        className={"form-required"}>*</span>
                                        {
                                            areaPlans === null && request.area_plans &&
                                            <a href={process.env.REACT_APP_API.replace('/api', '') + request.area_plans.url}
                                               target={"_blank"} rel={"noreferrer"}>
                                                <i className={"fa-solid fa-eye ms-2"}/>
                                            </a>
                                        }
                                    </div>
                                    <UploadFile
                                        file={areaPlans}
                                        setFile={setAreaPlans}
                                        className={"p-3"}
                                    />
                                </div>
                                <div className={"col-12"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'requests.cadastral_plan_with_stereo_coordinates'})}<span
                                        className={"form-required"}>*</span>
                                        {
                                            cadastralPlanWithStereoCoordinates === null && request.cadastral_plan_with_stereo_coordinates &&
                                            <a href={process.env.REACT_APP_API.replace('/api', '') + request.cadastral_plan_with_stereo_coordinates.url}
                                               target={"_blank"} rel={"noreferrer"}>
                                                <i className={"fa-solid fa-eye ms-2"}/>
                                            </a>
                                        }
                                    </div>
                                    <UploadFile
                                        file={cadastralPlanWithStereoCoordinates}
                                        setFile={setCadastralPlanWithStereoCoordinates}
                                        className={"p-3"}
                                    />
                                </div>
                                <div className={"col-12"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'requests.orthophoto_plan'})}<span
                                        className={"form-required"}>*</span>
                                        {
                                            orthophotoPlan === null && request.orthophoto_plan &&
                                            <a href={process.env.REACT_APP_API.replace('/api', '') + request.orthophoto_plan.url}
                                               target={"_blank"} rel={"noreferrer"}>
                                                <i className={"fa-solid fa-eye ms-2"}/>
                                            </a>
                                        }
                                    </div>
                                    <UploadFile
                                        file={orthophotoPlan}
                                        setFile={setOrthophotoPlan}
                                        className={"p-3"}
                                    />
                                </div>
                                <div className={"col-12"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'requests.land_certificate_extract'})}<span
                                        className={"form-required"}>*</span>
                                        {
                                            landCertificateExtract === null && request.land_certificate_extract &&
                                            <a href={process.env.REACT_APP_API.replace('/api', '') + request.land_certificate_extract.url}
                                               target={"_blank"} rel={"noreferrer"}>
                                                <i className={"fa-solid fa-eye ms-2"}/>
                                            </a>
                                        }
                                    </div>
                                    <UploadFile
                                        file={landCertificateExtract}
                                        setFile={setLandCertificateExtract}
                                        className={"p-3"}
                                    />
                                </div>
                                <div className={"col-12"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'requests.notarial_declaration_for_litigation'})}<span
                                        className={"form-required"}>*</span>
                                        {
                                            notarialDeclarationForLitigation === null && request.notarial_declaration_for_litigation &&
                                            <a href={process.env.REACT_APP_API.replace('/api', '') + request.notarial_declaration_for_litigation.url}
                                               target={"_blank"} rel={"noreferrer"}>
                                                <i className={"fa-solid fa-eye ms-2"}/>
                                            </a>
                                        }
                                    </div>
                                    <UploadFile
                                        file={notarialDeclarationForLitigation}
                                        setFile={setNotarialDeclarationForLitigation}
                                        className={"p-3"}
                                    />
                                </div>
                                <div className={"col-12"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'requests.notarial_declaration_for_compensation'})}<span
                                        className={"form-required"}>*</span>
                                        {
                                            notarialDeclarationFoCompensation === null && request.notarial_declaration_for_compensation &&
                                            <a href={process.env.REACT_APP_API.replace('/api', '') + request.notarial_declaration_for_compensation.url}
                                               target={"_blank"} rel={"noreferrer"}>
                                                <i className={"fa-solid fa-eye ms-2"}/>
                                            </a>
                                        }
                                    </div>
                                    <UploadFile
                                        file={notarialDeclarationFoCompensation}
                                        setFile={setNotarialDeclarationFoCompensation}
                                        className={"p-3"}
                                    />
                                </div>
                                <div className={"col-12"}>
                                    <div className={"mt-3 form-control"}>
                                        <label>
                                            <input type={"checkbox"}
                                                   checked={hasAttr}
                                                   required
                                                   onChange={() => {
                                                       setHasAttr(!hasAttr)
                                                   }}/>

                                            {intl.formatMessage({id: 'requests.has_attr'})}
                                           </label>
                                    </div>
                                </div>
                            </>
                        }

                        <div className={"col-12"}>
                            <div className={"form-control"}>
                            </div>
                        </div>
                        <div className={"col-12"}>
                            <div className={"form-control"}>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"footer"}>
                    <div className={"row"}>
                        <div className={"col-12 col-md-6"}>
                        </div>
                        <div className={"col-12 col-md-6 d-flex"}>
                            <Button
                                className={"btn-secondary mt-2 ms-auto"}
                                icon={"fa-regular fa-floppy-disk"}
                                value={intl.formatMessage({id: 'general.buttons.save'})}
                                disabled={saveLoading}
                                loading={saveLoading}
                                type={"submit"}
                            />
                        </div>
                    </div>
                </div>
            </form>

        </div>
    );
}

