import Button from "../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import {useNavigate, useOutletContext} from "react-router-dom";
import {toast} from "react-toastify";
import axios from "axios";
import errorParser from "../../../components/ErrorParser/ErrorParser";
import Selector from "../../../components/elements/Selector/Selector";
import LoadingCompHover from "../../../components/elements/LoadingCompHover/LoadingCompHover";
import LoadingComp from "../../../components/elements/LoadingComp/LoadingComp";

export default function AddClientForm({
                                          id,showBackButton, isOnSave = false, onSave = () => {}}) {

    const intl = useIntl();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')
    const clientTypes = [{
        value: 'individual',
        label: intl.formatMessage({id: 'clients.individual'})
    }, {
        value: 'company',
        label: intl.formatMessage({id: 'clients.company'})
    }
    ]

    const [type, setType] = useState('individual')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [disabledEmail, setDisabledEmail] = useState(false)
    const [phone, setPhone] = useState('')
    const [change, setChange] = useState(0)
    const [fiscalCode, setFiscalCode] = useState('')
    const [regComNumber, setRegComNumber] = useState('')
    const [legalRepresentativeFunction, setLegalRepresentativeFunction] = useState('')
    const [bankAccount, setBankAccount] = useState('')
    const [bank, setBank] = useState('')
    const [companyTitle, setCompanyTitle] = useState('')

    const [loading, setLoading] = useState(true)
    const [loadingCui, setLoadingCui] = useState(false)

    const [saveLoading, setSaveLoading] = useState(false)

    const {context} = useOutletContext()

    useEffect(() => {
        if (id !== 0) {
            getData()
        } else {
            setLoading(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/clients/' + id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data) {
                    setFirstName(response.data.first_name)
                    setLastName(response.data.last_name)
                    setEmail(response.data.email)
                    setDisabledEmail(response.data.user !== null)
                    setPhone(response.data.phone ?? '')
                    setFiscalCode(response.data.fiscal_code ?? '')
                    setRegComNumber(response.data.reg_com_number ?? '')
                    setLegalRepresentativeFunction(response.data.legal_representative_function ?? '')
                    setBankAccount(response.data.bank_account ?? '')
                    setBank(response.data.bank ?? '')
                    setCompanyTitle(response.data.company_title ?? '')
                    setType(response.data.type ?? '')
                    setLoading(false)

                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const save = async (e) => {
        e.preventDefault();
        setSaveLoading(true)
        let payload = {
            first_name: firstName,
            last_name: lastName,
            email: email,
            phone: phone,
            fiscal_code: fiscalCode,
            reg_com_number: regComNumber,
            legal_representative_function: legalRepresentativeFunction,
            bank_account: bankAccount,
            bank: bank,
            company_title: companyTitle,
            type: type
        }

        await axios.post(process.env.REACT_APP_API + '/clients' + (id !== 0 ? ('/' + id) : ''), payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                context.setContentUpdated(false)
                if (parseInt(response.data.status) === 1) {
                    toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))

                    if (isOnSave) {
                        onSave(response.data.client ?? {})
                    } else if (id === 0)
                        navigate(-1)
                } else {
                    toast.error(intl.formatMessage({id: 'errors.error.exist_client_with_this_email'}))
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })


        setSaveLoading(false)
    }

    const getCompanyInfo = async () => {

        if ((fiscalCode.length === 8 && !fiscalCode.includes('RO')) || (fiscalCode.length === 10 && fiscalCode.includes('RO'))) {
            setLoadingCui(true)
            axios.post(process.env.REACT_APP_API + '/company-info',
                {fiscal_code: fiscalCode}, {
                    headers: {
                        'Authorization': 'Bearer ' + jwt
                    }
                })
                .then(async (response) => {
                    if (response.data.data) {
                        setRegComNumber(response.data.data.cod_inmatriculare)
                        setCompanyTitle(response.data.data.nume)
                    }
                    setLoadingCui(false)
                })
                .catch(err => {
                    errorParser(err, intl)
                    setLoadingCui(false)
                })
        }


    }
    useEffect(() => {
        getCompanyInfo()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [change])

    if (loading) {
        return <LoadingComp/>
    }
    return (
        <form onSubmit={(e) => save(e)}>
            {
                loadingCui &&
                <LoadingCompHover/>
            }
            <div className={"content"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"form-control"}>
                            <div
                                className={"form-label"}>{intl.formatMessage({id: 'clients.type'})}<span
                                className={"form-required"}>*</span></div>
                            <Selector
                                required
                                options={clientTypes}
                                value={type}
                                onChange={(option) => {
                                    context.setContentUpdated(true)
                                    setType(option.value)
                                }}
                                isSearchable={true}
                                isOptionDisabled={(option) => option.disabled}
                            />
                        </div>
                    </div>
                    <div className={"col-md-6 col-12"}>
                        <div className={"form-control"}>
                            <div
                                className={"form-label"}>
                                {
                                    type === 'individual' ?
                                        intl.formatMessage({id: 'general.first_name'})
                                        :

                                        intl.formatMessage({id: 'general.legal_representative_first_name'})

                                }
                                <span
                                    className={"form-required"}>*</span></div>
                            <input type={"text"} className={"form-input"}
                                   value={firstName} required

                                   onChange={(e) => {
                                       context.setContentUpdated(true)
                                       setFirstName(e.target.value)
                                   }}
                            />
                        </div>
                    </div>
                    <div className={"col-md-6 col-12"}>
                        <div className={"form-control"}>
                            <div
                                className={"form-label"}>  {
                                type === 'individual' ?
                                    intl.formatMessage({id: 'general.last_name'})
                                    :
                                    intl.formatMessage({id: 'general.legal_representative_last_name'})
                            }<span
                                className={"form-required"}>*</span></div>
                            <input type={"text"} className={"form-input"}
                                   value={lastName} required

                                   onChange={(e) => {
                                       context.setContentUpdated(true)
                                       setLastName(e.target.value)
                                   }}
                            />
                        </div>
                    </div>
                    <div className={"col-md-6 col-sm-12"}>
                        <div className={"form-control"}>
                            <div
                                className={"form-label"}>{intl.formatMessage({id: 'general.email'})}<span
                                className={"form-required"}>*</span></div>
                            <input type={"email"} className={"form-input"}

                                   value={email} required disabled={disabledEmail}
                                   onChange={(e) => {
                                       context.setContentUpdated(true)
                                       setEmail(e.target.value)
                                   }}
                            />
                        </div>
                    </div>
                    <div className={"col-md-6 col-sm-12"}>
                        <div className={"form-control"}>
                            <div
                                className={"form-label"}>{intl.formatMessage({id: 'general.phone'})}<span
                                className={"form-required"}>*</span></div>
                            <input type={"tel"} className={"form-input"}
                                   value={phone} required

                                   onChange={(e) => {
                                       context.setContentUpdated(true)
                                       setPhone(e.target.value)
                                   }}
                            />
                        </div>
                    </div>
                    {
                        type === 'company' &&
                        <div className={"col-md-6 col-sm-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'settings.company.fiscal_code'})}<span
                                    className={"form-required"}>*</span></div>
                                <input type={"text"} className={"form-input"} required={true}

                                       value={fiscalCode} onChange={(e) => {
                                    context.setContentUpdated(true)
                                    setChange(change + 1)
                                    setFiscalCode(e.target.value)
                                }}/>
                            </div>
                        </div>
                    }
                    {
                        type === 'company' &&
                        <div className={"col-md-6 col-sm-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'settings.company.reg_com_number'})}<span
                                    className={"form-required"}>*</span></div>
                                <input type={"text"} className={"form-input"} required={true}

                                       value={regComNumber} onChange={(e) => {
                                    context.setContentUpdated(true)
                                    setRegComNumber(e.target.value)
                                }}/>
                            </div>
                        </div>
                    }
                    {
                        type === 'company' && <div className={"col-md-6 col-sm-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'settings.company.title'})}<span
                                    className={"form-required"}>*</span></div>
                                <input type={"text"} className={"form-input"} required={true}
                                       value={companyTitle}

                                       onChange={(e) => {
                                           context.setContentUpdated(true)
                                           setCompanyTitle(e.target.value)
                                       }}/>
                            </div>
                        </div>}
                    {
                        type === 'company' && <div className={"col-md-6 col-sm-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'settings.company.legal_representative_function'})}<span
                                    className={"form-required"}>*</span></div>
                                <input type={"text"} className={"form-input"} required={true}
                                       value={legalRepresentativeFunction}

                                       onChange={(e) => {
                                           context.setContentUpdated(true)
                                           setLegalRepresentativeFunction(e.target.value)
                                       }}/>
                            </div>
                        </div>}
                    <div className={"col-md-6 col-sm-12"}>
                        <div className={"form-control"}>
                            <div
                                className={"form-label"}>{intl.formatMessage({id: 'settings.company.bank_account'})}</div>
                            <input type={"text"} className={"form-input"}

                                   value={bankAccount} onChange={(e) => {
                                context.setContentUpdated(true)
                                setBankAccount(e.target.value)
                            }}/>
                        </div>
                    </div>
                    <div className={"col-md-6 col-sm-12"}>
                        <div className={"form-control"}>
                            <div
                                className={"form-label"}>{intl.formatMessage({id: 'settings.company.bank'})}</div>
                            <input type={"text"} className={"form-input"}

                                   value={bank} onChange={(e) => {
                                context.setContentUpdated(true)
                                setBank(e.target.value)
                            }}/>
                        </div>
                    </div>

                </div>
            </div>

            <div className={"footer"}>
                <div className={"row"}>
                    <div className={"col-12 col-md-6"}>
                        {showBackButton && <Button
                            className={"btn-light mt-2"}
                            icon={"fa-solid fa-chevron-left"}
                            value={intl.formatMessage({id: 'general.buttons.back'})}
                            onClick={() => {
                                context.setLeavePage(true)
                                context.setNextLink('/clients')
                            }}
                        />}
                    </div>
                    <div className={"col-12 col-md-6 d-flex"}>
                       <Button
                            className={"btn-secondary mt-2 ms-auto"}
                            icon={"fa-regular fa-floppy-disk"}
                            value={intl.formatMessage({id: 'general.buttons.save'})}
                            disabled={saveLoading}
                            loading={saveLoading}
                            type={"submit"}
                        />
                    </div>
                </div>
            </div>
        </form>
    );
}

