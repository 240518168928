import general from './general.json'
import errors from './errors.json'
import settings from './settings.json'
import login from './login.json'
import clients from './clients.json'
import products from './products.json'
import contracts from './contracts.json'
import invoices from './invoices.json'
import requests from './requests.json'

export const ro = {
    ...general,
    ...errors,
    ...login,
    ...settings,
    ...clients,
    ...products,
    ...contracts,
    ...invoices,
    ...requests
}
