import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../components/ErrorParser/ErrorParser";
import {NavLink} from "react-router-dom";
import './Companies.scss';
import moment from "moment";
import RangePicker from "../../../components/RangePicker/RangePicker";
import LoadingCompHover from "../../../components/elements/LoadingCompHover/LoadingCompHover";

export default function Companies() {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')

    const [companies, setCompanies] = useState([])
    const [sortBy, setSortBy] = useState('created_at')
    const [sort, setSort] = useState('desc')
    const [update, setUpdate] = useState(0)

    const [loading, setLoading] = useState(true)
    const [startDateActivity, setStartDateActivity] = useState(null)
    const [endDateActivity, setEndDateActivity] = useState(null)
    const [startDateCreate, setStartDateCreate] = useState(null)
    const [endDateCreate, setEndDateCreate] = useState(null)


    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        setLoading(true)
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [update, startDateActivity,startDateCreate, endDateActivity, endDateCreate])

    const getData = async () => {
        axios.post(process.env.REACT_APP_API + '/companies', {
            sort,
            sort_by: sortBy,
            start_activity: startDateActivity !== null ? moment(startDateActivity).toISOString() : null,
            start_create: startDateCreate !== null ? moment(startDateCreate).toISOString() : null,
            end_activity: endDateActivity !== null ? moment(endDateActivity).toISOString() : null,
            end_create: endDateCreate !== null ? moment(endDateCreate).toISOString() : null,
        },{
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setCompanies(response.data ?? [])
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }




    return (
        <div className="container">
            {
                loading &&
                <LoadingCompHover/>
            }
            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'settings.menu.companies'})}</h1>
                </div>
                <div className={"col-12"}>
                    <div className="card">
                        <div className={"header"}>
                            <div className={"row mt-3"}>
                                <div className={"col-md-6 col-12"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'settings.companies.table.last_access'})}</div>
                                    <RangePicker
                                        startDate={startDateActivity}
                                        endDate={endDateActivity}
                                        setEndDate={setEndDateActivity}
                                        setStartDate={setStartDateActivity}
                                    />
                                </div>
                                <div className={"col-md-6 col-12"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'settings.companies.table.created_at'})}</div>
                                    <RangePicker
                                        startDate={startDateCreate}
                                        endDate={endDateCreate}
                                        setEndDate={setEndDateCreate}
                                        setStartDate={setStartDateCreate}
                                    />
                                </div>

                            </div>
                        </div>
                        {
                            companies.length === 0 ?
                                <div className={"content"}>
                                    <div className={"alert alert-warning"}>
                                        {intl.formatMessage({id: 'settings.companies.alert_no_companies'})}
                                    </div>
                                </div>
                                :
                                <div className={"content p-0"}>
                                    <div className={"table mb-1 companies-table"}>

                                        <div className={"table-head"}>
                                            <div className={"row"} style={{cursor: "pointer"}}>
                                                <div className={"col-3 table-td"}
                                                     onClick={() => {
                                                         if (sortBy === 'name') {
                                                             if (sort === 'desc')
                                                                 setSort('asc')
                                                             else
                                                                 setSort('desc')

                                                             setUpdate(update + 1)
                                                         }else {
                                                             setSort('desc')
                                                             setSortBy('name')
                                                             setUpdate(update + 1)
                                                         }

                                                     }}>{intl.formatMessage({id: 'general.title'})}
                                                         <i className={"ms-3 " + (sortBy === 'name' ? (sort === 'desc' ? 'fa-solid fa-sort-down' : 'fa-solid fa-sort-up') : 'fa-solid fa-sort')}/>
                                            </div>
                                                <div className={"col-3 table-td"}
                                                     >{intl.formatMessage({id: 'general.email'})}
                                            </div>
                                            <div
                                                className={"col-2 table-td"}
                                                onClick={() => {
                                                    if (sortBy === 'last_activity') {
                                                        if (sort === 'desc')
                                                            setSort('asc')
                                                        else
                                                            setSort('desc')
                                                        setUpdate(update + 1)
                                                    }else {
                                                        setSortBy('last_activity')
                                                        setSort('desc')
                                                        setUpdate(update + 1)
                                                    }
                                                }}>{intl.formatMessage({id: 'settings.companies.table.last_access'})}
                                                <i className={"ms-3 " + (sortBy === 'last_activity' ? (sort === 'desc' ? 'fa-solid fa-sort-down' : 'fa-solid fa-sort-up') : 'fa-solid fa-sort')}/></div>
                                            <div
                                                className={"col-2 table-td"}
                                                onClick={() => {
                                                    if (sortBy === 'created_at') {
                                                        if (sort === 'desc')
                                                            setSort('asc')
                                                        else
                                                            setSort('desc')
                                                        setUpdate(update + 1)
                                                    }else {
                                                        setSortBy('created_at')
                                                        setSort('desc')
                                                        setUpdate(update + 1)
                                                    }
                                                }}>{intl.formatMessage({id: 'settings.companies.table.created_at'})}
                                                <i className={"ms-3 " + (sortBy === 'created_at' ? (sort === 'desc' ? 'fa-solid fa-sort-down' : 'fa-solid fa-sort-up') : 'fa-solid fa-sort')}/></div>
                                            <div
                                                className={"col-2 table-td"}
                                                onClick={() => {
                                                    if (sortBy === 'subscription_expiration_date') {
                                                        if (sort === 'desc')
                                                            setSort('asc')
                                                        else
                                                            setSort('desc')
                                                        setUpdate(update + 1)
                                                    }else {
                                                        setSortBy('subscription_expiration_date')
                                                        setSort('desc')
                                                        setUpdate(update + 1)
                                                    }
                                                }}>{intl.formatMessage({id: 'settings.companies.table.subscription_expiration_date'})}
                                                <i className={"ms-3 " + (sortBy === 'subscription_expiration_date' ? (sort === 'desc' ? 'fa-solid fa-sort-down' : 'fa-solid fa-sort-up') : 'fa-solid fa-sort')}/></div>

                                        </div>
                                    </div>


                                    <div className={"table-body"}>
                                        {
                                            companies.map((company, i) => (
                                                <NavLink to={"/company/" + company.id + "/users"} key={i}>
                                                    <div className={"table-row"} key={i}>
                                                        <div className={"row"}>
                                                            <div
                                                                className={"col-3 table-td"}>{company.title}</div>
                                                            <div
                                                                className={"col-3 table-td"}>{company.email}</div>
                                                            <div className={"col-2 table-td"}>
                                                                {company.last_activity !== '' ? moment.unix(company.last_activity_timestamp).format('Do/MM/YYYY H:mm') : ''}
                                                            </div>
                                                            <div className={"col-2 table-td"}>
                                                                {moment(company.createdAt).format('Do/MM/YYYY H:mm')}
                                                            </div>
                                                            <div className={"col-2 table-td"}>
                                                                {moment(company.subscription_expiration_date).format('Do/MM/YYYY H:mm')}
                                                            </div>

                                                        </div>
                                                    </div>
                                                </NavLink>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        }

                        <div className={"footer"}>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

