import './UploadComp.scss';
import {useRef} from "react";
import {useIntl} from "react-intl";

export default function UploadFile(
    {

        file,
        setFile,
        fileUrl,
        className = "",
        multiple = false
    }
) {

    const intl = useIntl()
    const inputRef = useRef()

    return (<div className={className}>
            <input type={"file"}
                   ref={inputRef}
                   accept="*"
                   style={{display: "none"}}
                   multiple={multiple}
                   onChange={(e) => {
                       if (multiple)
                           setFile(e.target.files)
                       else
                           setFile(e.target.files[0])
                   }}
            />
            <div className={"upload-file-comp"} onClick={() => inputRef.current.click()}>
                {
                    file !== null || fileUrl ?
                        <div className={"preview"}>
                            <div>
                                <i style={{fontSize: '2rem'}}
                                   className="fa-regular fa-file-pdf"></i>
                                <div className={"title"}>{file ? file.name : ''}</div>
                            </div>
                        </div>
                        :

                        <>
                            <i className="fa-regular fa-file-lines icon"></i>
                            <div
                                className={"title"}>{intl.formatMessage({id: "general.upload.pdf.title"})}</div>
                        </>

                }
            </div>
        </div>
    );
}

