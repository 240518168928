import SecondaryNavigation from "../../../../components/elements/SecondaryNavigation/SecondaryNavigation";
import {Outlet, useOutletContext, useParams} from "react-router-dom";
import {useIntl} from "react-intl";
import {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../../components/ErrorParser/ErrorParser";

export default function EditContractMenu() {

    const intl = useIntl()
    const jwt = localStorage.getItem('jwt')
    let params = useParams();
    const query = new URLSearchParams(window.location.search);
    const {context} = useOutletContext()
    const back = query.get('back')


    const [items, setItems] = useState([])
    useEffect(() => {
        getContract()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getContract = async () => {
        axios.get(process.env.REACT_APP_API + '/contracts/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.is_pdf || response.data.status === 'signed')
                    setItems([
                        {
                            'icon': "fa-solid fa-file-contract",
                            'title': intl.formatMessage({id: 'events.contracts.annexes'}),
                            'url': '/contracts/' + params.id + '/annexes?back=' + back
                        },
                        {
                            'icon': "fa-solid fa-chevron-left",
                            'title': intl.formatMessage({id: 'general.buttons.back'}),
                            'url': back,
                            'end': true
                        },
                    ])
                else
                    setItems([
                        {
                            'icon': "fa-regular fa-file",
                            'title': intl.formatMessage({id: 'events.contracts.edit'}),
                            'url': '/contracts/' + params.id +'?back=' + back,
                            'end': true
                        },
                        {
                            'icon': "fa-solid fa-file-contract",
                            'title': intl.formatMessage({id: 'events.contracts.annexes'}),
                            'url': '/contracts/' + params.id + '/annexes?back=' + back
                        },
                        {
                            'icon': "fa-solid fa-chevron-left",
                            'title': intl.formatMessage({id: 'general.buttons.back'}),
                            'url': back,
                            'end': true
                        },
                    ])
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    return (
        <div className="container">
            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'contracts.title'})}</h1>
                </div>

                <div className={"col-md-12 col-lg-3 col-xl-2 pb-4"}>
                    {items.length > 0 && <SecondaryNavigation
                        context={context}
                        items={items}
                    />}
                </div>
                <div className={"col-md-12 col-lg-9 col-xl-10"}>
                    <Outlet  context={{context}}/>
                </div>
            </div>
        </div>
    );
}

