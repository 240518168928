import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import Button from "../../../components/elements/Button/Button";
import axios from "axios";
import {toast} from "react-toastify";
import errorParser from "../../../components/ErrorParser/ErrorParser";
import LoadingCompHover from "../../../components/elements/LoadingCompHover/LoadingCompHover";
import {useNavigate} from "react-router-dom";
import Selector from "../../../components/elements/Selector/Selector";
import {sectionsAccess} from "../../../utils/constants";

export default function Register() {

    const intl = useIntl();
    const navigate = useNavigate()

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [repeatPassword, setRepeatPassword] = useState('')
    const [title, setTitle] = useState('')
    const [fiscalCode, setFiscalCode] = useState('')
    const [change, setChange] = useState(0)
    const [address, setAddress] = useState('')
    const [county, setCounty] = useState(0)
    const [zipcode, setZipcode] = useState('')
    const [counties, setCounties] = useState([])
    const [city, setCity] = useState('')
    const [regComNumber, setRegComNumber] = useState('')
    const [legalRepresentativeName, setLegalRepresentativeName] = useState('')
    const [legalRepresentativeFunction, setLegalRepresentativeFunction] = useState('')
    const [terms, setTerms] = useState(false)
    const [privacy, setPrivacy] = useState(false)

    const [loading, setLoading] = useState(false)
    const [loadingCui, setLoadingCui] = useState(false)

    useEffect(() => {
        setLoading(false)
        getCounties()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {

        getCompanyInfo()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [change])

    const getCounties = async () => {
        axios.get(process.env.REACT_APP_API + '/counties', {
            headers: {
            }
        })
            .then(async (response) => {
                let temp = []
                for (let i = 0; i < response.data.counties.length; i++) {
                    temp.push({
                        value: response.data.counties[i].id,
                        label: response.data.counties[i].title
                    })
                }

                setCounties(temp)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getCompanyInfo = async () => {

        if ((fiscalCode.length === 8 && !fiscalCode.includes('RO')) || (fiscalCode.length === 10 && fiscalCode.includes('RO'))) {
            setLoadingCui(true)
            axios.post(process.env.REACT_APP_API + '/company-info',
                {fiscal_code: fiscalCode}, {})
                .then(async (response) => {
                    if (response.data.data) {
                        setRegComNumber(response.data.data.cod_inmatriculare)
                        let countyFind = counties.find(item => item.label.toLowerCase() === response.data.data.adresa_judet.toLowerCase())
                        if (countyFind)
                            setCounty(countyFind.value)
                        setCity(response.data.data.adresa_localitate)
                        setAddress(response.data.data.adresa)
                        setTitle(response.data.data.nume)

                    }
                    setLoadingCui(false)
                })
                .catch(err => {
                    errorParser(err, intl)
                    setLoadingCui(false)
                })
        }
    }
    const register = async (e) => {
        e.preventDefault();
        setLoading(true)
        if (!password || !repeatPassword || password !== repeatPassword) {
            toast.error(intl.formatMessage({id: 'errors.passwords_not_match'}))
            setLoading(false)
            return
        }
        if (county === 0 || city === '') {
            toast.error(intl.formatMessage({id: "errors.please_fill_all_fields"}))
            return
        }
        let access = {}
        sectionsAccess.forEach(item => {
            access[item] = true
        })

        let countyFind = counties.find(item => item.value === county)
        let obj = {
            first_name: firstName,
            last_name: lastName,
            email: email,
            password: password,
            title: title,
            fiscal_code: fiscalCode,
            county: countyFind.label,
            city: city,
            address: address,
            reg_com_number: regComNumber,
            legal_representative_name: legalRepresentativeName,
            legal_representative_function: legalRepresentativeFunction,
            access
        }

        await axios.post(process.env.REACT_APP_API + '/auth/register', obj)
            .then(async (response) => {

                if (response && response.data && response.status && response.data.user) {
                    navigate('/verify-email/' + response.data.user.id)
                }
                setLoading(false)
            })
            .catch(err => {
                errorParser(err,intl)
            })

        setLoading(false)
    }
    return (
        <div className="login">

            <div className={"logo"}>
                <img src={"/images/logo.svg"} alt={""}/>
            </div>

            {
                loadingCui &&
                <LoadingCompHover/>
            }

            <form onSubmit={(e) => register(e)}>
                <div className={"container"}>

                    <div className={"form-control"}>
                        <div
                            className={"form-label"}>{intl.formatMessage({id: 'settings.account.first_name'})}<span
                            className={"form-required"}>*</span></div>
                        <input type={"text"} className={"form-input"} required
                               value={firstName} onChange={(e) => setFirstName(e.target.value)}/>
                    </div>
                    <div className={"form-control"}>
                        <div className={"form-label"}>{intl.formatMessage({id: 'settings.account.last_name'})}<span
                            className={"form-required"}>*</span></div>
                        <input type={"text"} className={"form-input"} required
                               value={lastName} onChange={(e) => setLastName(e.target.value)}/>
                    </div>
                    <div className={"form-control"}>
                        <div className={"form-label"}>{intl.formatMessage({id: 'general.email'})}<span
                            className={"form-required"}>*</span></div>
                        <input type={"email"} className={"form-input"}
                               value={email} onChange={(e) => {
                            setEmail(e.target.value)
                        }}/>
                    </div>

                    <div className={"form-control"}>
                        <div
                            className={"form-label"}>{intl.formatMessage({id: 'settings.account.password'})}<span
                            className={"form-required"}>*</span></div>
                        <input type={"password"} className={"form-input"} required
                               value={password} onChange={(e) => setPassword(e.target.value)}/>
                    </div>
                    <div className={"form-control"}>
                        <div
                            className={"form-label"}>{intl.formatMessage({id: 'settings.account.confirm_password'})}<span
                            className={"form-required"}>*</span></div>
                        <input type={"password"} className={"form-input"}
                               value={repeatPassword} required
                               onChange={(e) => setRepeatPassword(e.target.value)}/>
                    </div>
                    <div className={"form-control"}>
                        <div
                            className={"form-label"}>{intl.formatMessage({id: 'settings.company.fiscal_code'})}<span
                            className={"form-required"}>*</span></div>
                        <input type={"text"} className={"form-input"} required={true} maxLength={8}
                               value={fiscalCode} onChange={(e) => {
                            setChange(change + 1)
                            if (e.target.value.length > 8)
                                setFiscalCode(e.target.value.replace('RO', '').replace('ro', '').replace('Ro', ''))
                            else
                                setFiscalCode(e.target.value)
                        }}/>
                    </div>
                    <div className={"form-control"}>
                        <div
                            className={"form-label"}>{intl.formatMessage({id: 'settings.company.title'})}<span
                            className={"form-required"}>*</span></div>
                        <input type={"text"} className={"form-input"} required={true}
                               value={title} onChange={(e) => setTitle(e.target.value)}/>
                    </div>
                    <div className={"form-control"}>
                        <div
                            className={"form-label"}>{intl.formatMessage({id: 'settings.company.reg_com_number'})}<span
                            className={"form-required"}>*</span></div>
                        <input type={"text"} className={"form-input"} id={'reg-com-number-1'} required={true}
                               value={regComNumber} onChange={(e) => setRegComNumber(e.target.value)}/>
                    </div>
                    <div className={"form-control"}>
                        <div
                            className={"form-label"}>{intl.formatMessage({id: 'general.county'})}<span
                            className={"form-required"}>*</span></div>
                        <Selector
                            required
                            options={counties}
                            value={county}
                            key={county}
                            onChange={(option) => {
                                setCounty(option.value)
                                setCity('')
                            }}
                            isSearchable={true}
                            isOptionDisabled={(option) => option.disabled}
                        />
                    </div>
                    <div className={"form-control"}>
                        <div
                            className={"form-label"}>{intl.formatMessage({id: 'general.city'})}<span
                            className={"form-required"}>*</span></div>
                        <input type={"text"} className={"form-input"} required={true}
                               value={city}
                               onChange={(e) => setCity(e.target.value)}
                        />
                    </div>
                    <div className={"form-control"}>
                        <div
                            className={"form-label"}>{intl.formatMessage({id: 'settings.company.address'})}<span
                            className={"form-required"}>*</span></div>
                        <input type={"text"} className={"form-input"} required={true}
                               value={address} onChange={(e) => setAddress(e.target.value)}/>
                    </div>

                    <div className={"form-control"}>
                        <div
                            className={"form-label"}>{intl.formatMessage({id: 'settings.company.zipcode'})}<span
                            className={"form-required"}>*</span></div>
                        <input type={"text"} className={"form-input"} required={true}
                               value={zipcode} onChange={(e) => setZipcode(e.target.value)}/>
                    </div>
                    <div className={"form-control"}>
                        <div
                            className={"form-label"}>{intl.formatMessage({id: 'settings.company.legal_representative_name'})}<span
                            className={"form-required"}>*</span></div>
                        <input type={"text"} className={"form-input"} required={true}
                               value={legalRepresentativeName}
                               onChange={(e) => setLegalRepresentativeName(e.target.value)}/>
                    </div>
                    <div className={"form-control"}>
                        <div
                            className={"form-label"}>{intl.formatMessage({id: 'settings.company.legal_representative_function'})}<span
                            className={"form-required"}>*</span></div>
                        <input type={"text"} className={"form-input"} required={true}
                               value={legalRepresentativeFunction}
                               onChange={(e) => setLegalRepresentativeFunction(e.target.value)}/>
                    </div>
                    <div className={"col-12"}>
                        <div className={"mt-3 form-control"}>
                            <label>
                                <input type={"checkbox"}
                                       checked={terms}
                                       required
                                       onChange={() => {
                                           setTerms(!terms)
                                       }}/>

                                {intl.formatMessage({id: 'settings.company.agree'})}
                              <a href={"https://gazmanager.ro/termeni-si-conditii/"}> {intl.formatMessage({id: 'settings.company.accept_terms'})}</a>
                            </label>
                        </div>
                    </div>
                    <div className={"col-12"}>
                        <div className={"mt-3 form-control"}>
                            <label>
                                <input type={"checkbox"}
                                       checked={privacy}
                                       required
                                       onChange={() => {
                                           setPrivacy(!privacy)
                                       }}/>

                                {intl.formatMessage({id: 'settings.company.agree'})}
                               <a href={"https://gazmanager.ro/confidentialitate/"}> {intl.formatMessage({id: 'settings.company.privacy'})}</a>
                            </label>
                        </div>
                    </div>
                    <Button
                        className={"btn-primary mt-4"}
                        value={intl.formatMessage({id: 'login.signup'})}
                        type={'submit'}
                        loading={loading}
                    />
                    <a href={"/"} className={"mt-3"} style={{textAlign: 'center'}}>
                        {intl.formatMessage({id: 'login.signin'})}
                    </a>
                </div>
            </form>
        </div>
    );
}

