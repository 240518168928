import SecondaryNavigation from "../../../../components/elements/SecondaryNavigation/SecondaryNavigation";
import {Outlet, useOutletContext, useParams} from "react-router-dom";
import {useIntl} from "react-intl";

export default function EditClientMenu() {

    const intl = useIntl()
    const {context} = useOutletContext()
    let params = useParams();

    return (
        <div className="container">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'general.sidebar.clients'})}</h1>
                </div>

                <div className={"col-md-12 col-lg-3 col-xl-2 pb-4"}>

                    <SecondaryNavigation
                        context={context}
                        items={[
                            {
                                'icon': "fa-solid fa-pen",
                                'title': intl.formatMessage({id: 'general.edit_client'}),
                                'url': '/clients/'+params.id+'/edit',
                            },
                            {
                                'icon': "fa-solid fa-location-dot",
                                'title': intl.formatMessage({id: 'general.places'}),
                                'url': '/clients/'+params.id+'/places',
                            },
                        ]}
                    />
                </div>
                <div className={"col-md-12 col-lg-9 col-xl-10"}>
                    <Outlet  context={{context}}/>
                </div>
            </div>




        </div>
    );
}

