import {useIntl} from "react-intl";
import Button from "../../../components/elements/Button/Button";
import InvoicesTable from "../../../components/Invoice/InvoicesTable/InvoicesTable";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../components/ErrorParser/ErrorParser";
import LoadingComp from "../../../components/elements/LoadingComp/LoadingComp";
import Paginate from "../../../components/elements/Paginate/Paginate";

export default function InvoicesList() {
    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')

    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [invoices, setInvoices] = useState([])
    const [selectedInvoices, setSelectedInvoices] = useState([])
    const [loadingDownload, setLoadingDownload] = useState(false)
    const [user, setUser] = useState(null)


    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getData()
        }, 600)

        return () => clearTimeout(delayDebounceFn)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/invoices/list/' + page, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.invoices) {
                    setInvoices(response.data.invoices)
                    setTotal(response.data.total ?? 0)
                }
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }


    const base64toBlob = (data) => {
        // Cut the prefix `data:application/pdf;base64` from the raw base 64
        const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length);

        const bytes = atob(base64WithoutPrefix);
        let length = bytes.length;
        let out = new Uint8Array(length);

        while (length--) {
            out[length] = bytes.charCodeAt(length);
        }

        return new Blob([out], {type: 'application/pdf'});
    };
    const downloadInvoices = () => {

        let text = '';
        selectedInvoices.forEach((contr, i) => {
            text += contr;
            if (i + 1 !== selectedInvoices.length) {
                text += ','
            }
        })

        setLoadingDownload(true)
        axios.get(process.env.REACT_APP_API + '/invoices/download-bulk/' + user.id + '/' + user.company.id + '?invoices=' + text, {})
            .then((response) => {
                if (window.alityAppView) {
                    window.ReactNativeWebView.postMessage(JSON.stringify({
                        action: 'openLink',
                        url: response.data.file,
                    }))

                } else {
                    var a = document.createElement('a');
                    a.href = response.data.file;
                    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                    a.click();
                    a.remove();  //afterwards we remove the element again
                }
                setLoadingDownload(false)
            })
            .catch(e => console.log(e))

    }

    useEffect(() => {
        getUser()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getUser = async () => {
        axios.get(process.env.REACT_APP_API + '/users/me', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setUser(response.data ?? [])
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="card invoices-client">
            <div className={"header align-items-center"}>
                <div className={"row"}>
                    <div className={"col-xl-4 col-md-4 col-sm-12"}>
                        <p>{intl.formatMessage({id: 'events.menu.invoices'})}</p>
                    </div>
                    <div className={"col-xl-8 col-md-8 col-sm-12 float-right"}>
                        {
                            invoices.length > 0 &&
                            <Button
                                className={"btn-primary d-flex align-items-center ms-3 " + (selectedInvoices.length === 0 ? 'disabled' : '')}
                                icon={"fa-solid fa-download"}
                                style={{float: 'right'}}
                                loading={loadingDownload}
                                value={intl.formatMessage({id: "general.buttons.download"})}
                                onClick={() => downloadInvoices()}
                                disabled={selectedInvoices.length === 0}
                            />
                        }
                    </div>
                </div>
            </div>
            {
                invoices.length === 0 ?
                    <div className={"content"}>

                        <div
                            className={"alert alert-warning"}>{intl.formatMessage({id: 'invoices.alert_no_invoices_found'})}</div>
                    </div>
                    :
                    <InvoicesTable
                        invoices={invoices}
                        getData={getData}
                        selectedInvoices={selectedInvoices}
                        setSelectedInvoices={setSelectedInvoices}
                        back={'/invoices/'}
                    />

            }
            <div className={"footer d-flex justify-content-center"}>
                <Paginate
                    page={page}
                    setPage={setPage}
                    totalItems={total}
                />
            </div>
        </div>

    );
}

