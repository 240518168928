import {useIntl} from "react-intl";
import React from "react";
import AddPlaceForm from "./AddPlaceForm";
import {useParams} from "react-router-dom";

export default function AddPlace() {

    const intl = useIntl();
    let params = useParams();

    return (
        <div className="card">
            <div className={"header"}><p>{intl.formatMessage({id: 'clients.add_place'})}</p>

            </div>

             <AddPlaceForm
                 clientId={params.id}
                 id={0}
             />

        </div>
    );
}

