import Button from "../../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import axios from "axios";
import errorParser from "../../../../../components/ErrorParser/ErrorParser";
import LoadingComp from "../../../../../components/elements/LoadingComp/LoadingComp";
import Selector from "../../../../../components/elements/Selector/Selector";

export default function EditSeries() {

    const intl = useIntl();
    const navigate = useNavigate()
    let params = useParams();
    const jwt = localStorage.getItem('jwt')

    const [series, setSeries] = useState('')
    const [type, setType] = useState('')

    const [saveLoading, setSaveLoading] = useState(false)
    const [nextNumber, setNextNumber] = useState(1)
    const [loading, setLoading] = useState(true)
    const {context} = useOutletContext()


    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/document-series/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setSeries(response.data.series)
                setType(response.data.for)
                setNextNumber(response.data.next_number)

                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const save = async (e) => {
        e.preventDefault();

        if (series === '' || type === '') {
            toast.error(intl.formatMessage({id: "errors.please_fill_all_fields"}))
            return
        }
        setSaveLoading(true)

        await axios.put(process.env.REACT_APP_API + '/document-series/' + params.id, {
            series,
            for: type,
            next_number: nextNumber
        }, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                context.setContentUpdated(false)
                navigate(-1)
            })
            .catch(err => {
                errorParser(err, intl)
            })


        setSaveLoading(false)
    }

    if (loading) {
        return <LoadingComp/>
    }

    return (

        <div className="card">

            <div className={"header"}><p>{intl.formatMessage({id: 'settings.series.edit_series'})}</p></div>

            <form onSubmit={(e) => save(e)}>
                <div className={"content"}>
                    <div className={"row"}>
                        <div className={"col-md-6 col-sm-12"}>
                            <div className={"form-control"}>
                                <div className={"form-label"}>{intl.formatMessage({id: 'settings.series.series'})}<span
                                    className={"form-required"}>*</span></div>
                                <input type={"text"} className={"form-input"}
                                       value={series} onChange={(e) => {
                                    context.setContentUpdated(true)
                                    setSeries(e.target.value)
                                }}/>
                            </div>
                        </div>

                        <div className={"col-md-6 col-sm-12"}>
                            <div className={"form-control"}>
                                <div className={"form-label"}>{intl.formatMessage({id: 'settings.series.next_number'})}<span
                                    className={"form-required"}>*</span></div>
                                <input type={"number"} className={"form-input"} required min={1}
                                       value={nextNumber} onChange={(e) => {
                                    context.setContentUpdated(true)
                                    setNextNumber(e.target.value)
                                }}/>
                            </div>
                        </div>
                        <div className={"col-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'settings.series.type'})}<span
                                    className={"form-required"}>*</span></div>
                                <Selector
                                    required
                                    options={[{
                                        value: 'contract',
                                        label: intl.formatMessage({id: 'settings.series.type.contract'})
                                    },{
                                        value: 'offer',
                                        label: intl.formatMessage({id: 'settings.series.type.offer'})
                                    }]}
                                    value={type}
                                    onChange={(option) => {
                                        context.setContentUpdated(true)
                                        setType(option.value)
                                    }}
                                    isSearchable={true}
                                    isOptionDisabled={(option) => option.disabled}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"footer"}>
                    <div className={"row"}>
                        <div className={"col-12 col-md-6"}>
                            <Button
                                className={"btn-light mt-2"}
                                icon={"fa-solid fa-chevron-left"}
                                value={intl.formatMessage({id: 'general.buttons.back'})}
                                onClick={() => {
                                    context.setLeavePage(true)
                                    context.setNextLink('/company/documents-series')
                                }}
                            />
                        </div>
                        <div className={"col-12 col-md-6 d-flex"}>
                            <Button
                                className={"btn-secondary mt-2 ms-auto"}
                                icon={"fa-regular fa-floppy-disk"}
                                value={intl.formatMessage({id: 'general.buttons.save'})}
                                disabled={saveLoading}
                                loading={saveLoading}
                                type={'submit'}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>

    );
}

